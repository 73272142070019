import React from 'react'

const Rai = () => {
  return (
    <>
   <div className='thirdImage'>
        

      
        </div>
     <section className='aboutSection'>
     <div className='container'>
     <div className="row">
       <div className="col-sm-6">
         
           <img src="/Data/Images/productimages/rai.png" className="w-75 h-75 border-bottom mt-4 mx-auto aboutImg" alt="jeerapic"/>
          
       </div>
       <div className="col-sm-6 mt-3">
        
             <h1 className="aboutHeading">Mustard Seeds (Rai)</h1>
             <h6 className='mt-4'><strong>Colour  :  </strong>Black, Brown</h6>
             <h6 className='mt-4'><strong>Taste  :  </strong>Spicy, Strong</h6>
             <h6 className='mt-4'><strong>Use  :  </strong>Spice Blend</h6>
            
             <p className="mt-3 aboutContent">
             Mustard seeds, derived from the mustard plant (Brassica juncea), are globally cultivated for their culinary versatility and health benefits. Major producers of mustard seeds include Canada, India, and China. These small, round seeds have a pungent flavor and are available in various colors, including yellow, brown, and black. Mustard seeds are a rich source of essential nutrients such as selenium, omega-3 fatty acids, and antioxidants, contributing to heart health and potentially reducing inflammation. Culinary uses of mustard seeds are extensive; they are a fundamental component in the production of mustard condiments, adding depth and heat to a variety of dishes. In Indian cuisine, mustard seeds are often tempered in hot oil to release their distinctive flavor before being added to curries and pickles. Mustard plants thrive in cool climates with well-drained soil, and the recommended growing conditions include temperatures ranging from 50°F to 75°F (10°C to 24°C). The versatility of mustard seeds in both flavor enhancement and potential health benefits makes them a valuable and widely used spice in kitchens across the globe.
            </p>
             
       </div>
     </div>
     
     </div>
     </section>
        
   </>
  )
}

export default Rai