import React, { useRef, useState } from "react";
import emailjs from '@emailjs/browser';
import { useAlert } from "react-alert";
const Result = () =>{
  return(
      <p>Your message has been successfully sent</p>
  )
}

const Contactform = () => {
   
  const alert = useAlert();
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_y36l0et', 'template_t9d4t47', form.current, 'aeYYQruDF4s9pXEss')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
      alert.success("Submitted successfully");
  };
  
 
   
    return (
    <>
     
    <div className="card h-100 contactData">
      <div className="card-body">
        <h5 className="card-title text-center">Make an Enquiry</h5>
        <div className='underline mx-auto mb-5' ></div>
        <form ref={form} onSubmit={sendEmail}>
        <div className='row'>
        <div className='col-md-6'>
       
        <div className="mb-3">
  <label htmlFor="name" className="form-label">Name<span  style={{color:"red"}}> *</span></label>
  <input type="name" className="form-control" id="name" name='name'  minLength = {5} required/>
</div>
</div>
<div className='col-md-6'>
<div className="mb-3">
  <label htmlFor="email" className="form-label">Email address<span  style={{color:"red"}}> *</span></label>
  <input type="email" className="form-control" id="email" name='email'aria-describedby="emailHelp" required/>
</div>
</div>
</div>


        <div className='row'>
        <div className='col-md-6'>
        <div className="mb-3">
  <label htmlFor="mobile" className="form-label">Mobile</label>
  <input type="mobile" className="form-control" id="mobile" name='mobile'  minLength = {10}/>
</div>
</div>
<div className='col-md-6'>
<div className="mb-3">
  <label htmlFor="subject" className="form-label">Subject</label>
  <input type="subject" className="form-control" id="subject" name='subject'  minLength = {5}/>
</div>
</div>
</div>
<div className="mb-3">
  <label htmlFor="message" className="form-label">Message<span  style={{color:"red"}}> *</span></label>
  <textarea className="form-control" id="message" rows="3" name='message'   minLength = {5} required></textarea>
</div>
        <button type='submit' className="btn" style={{backgroundColor:"#f76c2f",color:"white"}}>Submit</button>
        </form>
      </div>
    </div>
  


    </>
  )
}

export default Contactform