import React from 'react'
import { Link } from 'react-router-dom'
import { FaEnvelope, FaMapMarkerAlt, FaPhoneSquareAlt} from 'react-icons/fa';

const Footer = () => {
  return (
    <section className='section footer text-white'>
        <div className='container'>
            <div className='row'>
                <div className='col-md-3'>
                    <h5>Shree Shyam Spices Pvt. Ltd.</h5>
                    <div className='underline mb-5'></div>
                    <p className='text-white'>We are manufacturer of various kinds of spices. We have advanced high-tech machinery. We provide premium quality products and services to our customers.</p>
                </div>

                <div className='col-md-3'>
                    <h5>Quick Links</h5>
                    <div className='underline mb-5'></div>
                    <div><Link to = "/">Home</Link></div>
                    <div><Link to = "/about">About us</Link></div>
                    <div><Link to = "/services">Our Services</Link></div>
                    <div><Link to = "/products">Our Products</Link></div>
                    <div><Link to = "/harvestchart">Harvest Chart</Link></div>
                    <div><Link to = "/contact">Contact us</Link></div>
                </div>

                <div className='col-md-3'>
                    <h5>Products</h5>
                    <div className='underline mb-5'></div>
                    <div><Link to = "/product/jeera">Cumin seeds</Link></div>
                    <div><Link to = "/product/ajwain">Carom seeds</Link></div>
                    <div><Link to = "/product/saunf">Fennel seeds</Link></div>
                    <div><Link to = "/product/dhaniya">Coriander seeds</Link></div>
                    <div><Link to = "/product/rai">Mustard seeds</Link></div>
                    <div><Link to = "/product/methi-dana">Fenugreek</Link></div>
                    <div><Link to = "/product/til">Sesame seeds</Link></div>
                    <div><Link to = "/product/lal-mirch">Red-Chilly</Link></div>
                    
                </div>

                <div className='col-md-3'>
                    <h5>Get in touch</h5>
                    <div className='underline mb-5'></div>
                    <div><p className='text-white mb-1'><FaMapMarkerAlt/> Shree Shyam Spices Private Limited</p></div>
                    <div><p className='text-white mb-1'>22/159, Sindhu Nagar, Bhilwara-311001, Rajasthan, India</p></div>
                    <div><p className='text-white mb-1'><FaEnvelope/> info@shreeshyamspices.com</p></div>
                    <div><p className='text-white mb-1'><FaPhoneSquareAlt/> 8209041115</p></div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Footer