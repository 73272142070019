import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./Components/Home";
import About from "./Components/About";
import Services from "./Components/Services";
import Footer from "./Components/Footer";
import Contactus from "./Components/Contactus";

import Harvestchart from "./Components/Harvestchart";
import ProductContainer from "./Components/ProductContainer";

import { useEffect, useState } from "react";

import Navbar from "./Components/Navbar";
import ScrollToTop from "./Components/ScrollToTop";
import Jeera from "./Components/products/Jeera";
import Ajwain from "./Components/products/Ajwain";
import Saunf from "./Components/products/Saunf";
import Dhania from "./Components/products/Dhania";
import Rai from "./Components/products/Rai";
import MethiDana from "./Components/products/MethiDana";
import Til from "./Components/products/Til";
import LalMirch from "./Components/products/LalMirch";

function App() {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100);
    });
  }, []);

  return (
    <div>
      <>
        <ScrollToTop />
        {<Navbar scroll={scroll} />}

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/products" element={<ProductContainer />} />

          <Route exact path="/product/jeera" element={<Jeera />} />
          <Route exact path="/product/ajwain" element={<Ajwain />} />
          <Route exact path="/product/saunf" element={<Saunf />} />
          <Route exact path="/product/dhania" element={<Dhania />} />
          <Route exact path="/product/rai" element={<Rai />} />
          <Route exact path="/product/methi-dana" element={<MethiDana />} />
          <Route exact path="/product/til" element={<Til />} />
          <Route exact path="/product/lal-mirch" element={<LalMirch />} />
          <Route exact path="/harvestchart" element={<Harvestchart />} />
          <Route exact path="/contact" element={<Contactus />} />
        </Routes>

        <Footer />
        <div className="fixed-bottom right-100 p-3" style={{zIndex:"6",left:"initial"}}>
          <a href="https://wa.me/918209041115" target="_blank">
          <img src="Data/Images/whatsapp.png" width="60"/>
          </a>
        </div>
      </>
    </div>
  );
}

export default App;
