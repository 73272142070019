import React from 'react'

const Til = () => {
  return (
    <>
    <div className='thirdImage'>
        

      
        </div>
      <section className='aboutSection'>
      <div className='container'>
      <div className="row">
        <div className="col-sm-6">
          
            <img src="/Data/Images/productimages/til.png" className="w-75 h-75 border-bottom mt-4 mx-auto aboutImg" alt="jeerapic"/>
           
        </div>
        <div className="col-sm-6 mt-3">
         
              <h1 className="aboutHeading">Sesame Seeds (Til)</h1>
              <h6 className='mt-4'><strong>Colour  :  </strong>Brown, White</h6>
              <h6 className='mt-4'><strong>Taste  :  </strong>Sweet, Nutty</h6>
              <h6 className='mt-4'><strong>Use  :  </strong>Seasoning, Medicinal</h6>
             
              <p className="mt-3 aboutContent">
              Sesame seeds, derived from the flowering plant Sesamum indicum, are cultivated for their versatile applications and nutritional benefits. Globally, the largest producers of sesame seeds include countries like India, China, Sudan, and Myanmar. These tiny, oil-rich seeds are a rich source of essential nutrients, including healthy fats, protein, and various minerals. Their nutritional profile contributes to heart health, bone strength, and overall well-being. Sesame seeds are employed in diverse culinary endeavors, from baking and cooking to garnishing salads and stir-fries. They are a key ingredient in tahini, a popular Middle Eastern condiment, and are often sprinkled atop bread and pastries. Additionally, sesame oil, extracted from the seeds, is widely used in cooking and as a flavor enhancer. Sesame seeds thrive in warm climates with well-drained soil, and they are generally recommended for cultivation in regions with temperatures between 68-77°F (20-25°C). The cultivation of sesame seeds not only provides a valuable crop for various industries but also offers nutritional benefits that contribute to a balanced and healthy diet.
             </p>
              
        </div>
      </div>
      
      </div>
      </section>
         
    </>
  )
}

export default Til