import React from 'react'

const Jeera = () => {
  return (
    <>
     <div className='thirdImage'>
        

      
      </div>
      <section className='aboutSection'>
      <div className='container'>
      <div className="row">
        <div className="col-sm-6">
          
            <img src="/Data/Images/productimages/jeera.png" className="w-75 h-75 border-bottom mt-4 mx-auto aboutImg" alt="jeerapic"/>
           
        </div>
        <div className="col-sm-6 mt-3">
         
              <h1 className="aboutHeading">Cumin Seeds (Jeera)</h1>
              <h6 className='mt-4'><strong>Colour  :  </strong>Black, Brown</h6>
              <h6 className='mt-4'><strong>Taste  :  </strong>Spicy, Aromatic</h6>
              <h6 className='mt-4'><strong>Use  :  </strong>Seasoning, Herbs</h6>
             
              <p className="mt-3 aboutContent">
              Cumin seeds, scientifically known as Cuminum cyminum, are aromatic seeds derived from the flowering plant of the parsley family. India stands as the largest global producer and exporter of these seeds, with the states of Gujarat and Rajasthan playing pivotal roles in their cultivation. The country's favorable climate and agricultural conditions contribute to its prominence in cumin production, and India's output significantly influences the global cumin market. These small, elongated seeds boast a distinctive and slightly nutty taste, enhancing the depth of various dishes. Widely utilized in culinary traditions across the globe, cumin seeds impart a warm, earthy flavor with a hint of citrus, making them a staple in many spice blends. Beyond India, other notable cumin producers include Iran, Syria, Turkey, and China. In addition to being a culinary delight, cumin seeds are believed to possess digestive properties and are often used in traditional medicine, marking their enduring significance in enriching the global tapestry of flavors.
             </p>
              
        </div>
      </div>
      
      </div>
      </section>
         
    </>
  )
}

export default Jeera