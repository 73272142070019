import React from 'react'

const Dhania = () => {
  return (
    <>
<div className='thirdImage'>
        

      
        </div>
      <section className='aboutSection'>
      <div className='container'>
      <div className="row">
        <div className="col-sm-6">
          
            <img src="/Data/Images/productimages/coriander.png" className="w-75 h-75 border-bottom mt-4 mx-auto aboutImg" alt="jeerapic"/>
           
        </div>
        <div className="col-sm-6 mt-3">
         
              <h1 className="aboutHeading">Coriander Seeds (Dhaniya)</h1>
              <h6 className='mt-4'><strong>Colour  :  </strong>Green, White, Brown</h6>
              <h6 className='mt-4'><strong>Taste  :  </strong>Sweet, Aromatic</h6>
              <h6 className='mt-4'><strong>Use  :  </strong>Seasoning, Herbs</h6>
             
              <p className="mt-3 aboutContent">
              Coriander seeds, harvested from the coriander plant (Coriandrum sativum), play a significant role in global cuisine and traditional medicine. Major producers of coriander seeds include India, Russia, and Morocco. These small, round seeds boast a citrusy, slightly sweet flavor and are a staple in spice blends worldwide. Coriander seeds offer various health benefits, containing antioxidants and exhibiting anti-inflammatory properties. They are known to aid digestion, regulate blood sugar levels, and contribute to heart health. In culinary applications, coriander seeds are extensively used in both whole and ground forms. They are a key ingredient in spice mixes, curry powders, and pickling spices. In many cuisines, coriander seeds are toasted before use to enhance their aroma and flavor. The plant prefers well-drained soil and thrives in moderate temperatures, making it suitable for cultivation in regions with a Mediterranean-like climate. Coriander seeds not only add depth to a wide array of dishes but also bring potential health benefits, making them a versatile and valued spice in kitchens around the world.
             </p>
              
        </div>
      </div>
      
      </div>
      </section>
         
    </>
  )
}

export default Dhania