import React from 'react'



import MetaData from './Metadata'
import Product from './Product';




const ProductContainer = () => {
  
 
const products = [{
                  img:"Data/Images/productimages/jeera.png",
                  name1:"CUMIN SEEDS",
                  name2:"jeera"
                },
                  {
                    img:"/Data/Images/productimages/ajwain.png",
                    name1:"CAROM SEEDS",
                    name2:"ajwain"},
                    {
                      img:"/Data/Images/productimages/sauf.png",
                      name1:"FENNEL SEEDS",
                      name2:"saunf"},
                      {
                        img:"/Data/Images/productimages/coriander.png",
                        name1:"CORIANDER SEEDS",
                        name2:"dhania"},
                        {
                          img:"/Data/Images/productimages/rai.png",
                          name1:"MUSTARD SEEDS",
                          name2:"rai"},
                          {
                            img:"/Data/Images/productimages/Methi-dana.png",
                            name1:"FENUGREEK",
                            name2:"methi-dana"},
                            {
                              img:"/Data/Images/productimages/til.png",
                              name1:"SESAME SEEDS",
                              name2:"til"},
                              {
                                img:"/Data/Images/productimages/lal-mirch.png",
                                name1:"RED-CHILLY",
                                name2:"lal-mirch"}];
   
    
  return (
    <>
     <MetaData title="Products - Shree Shyam Spices" />
     <div className='secondImage'>
        

  <div className="hero-text">
  
  <h1>Products</h1>
  
  
</div>
</div>
<section className='section'>
<div className='container d-flex flex-row justify-content-evenly flex-wrap align-content-start mt-4'>
{products && products.map((product) => 
  <Product product = {product} key ={product.name1}/>
)}
</div>
</section>
    </>
  )
}

export default ProductContainer