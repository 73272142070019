import React from 'react'

const LalMirch = () => {
  return (
    <>
<div className='thirdImage'>
        

      
        </div>
     <section className='aboutSection'>
     <div className='container'>
     <div className="row">
       <div className="col-sm-6">
         
           <img src="/Data/Images/productimages/lal-mirch.png" className="w-75 h-75 border-bottom mt-4 mx-auto aboutImg" alt="jeerapic"/>
          
       </div>
       <div className="col-sm-6 mt-3">
        
             <h1 className="aboutHeading">Red-Chilly (Lal-Mirch)</h1>
             <h6 className='mt-4'><strong>Colour  :  </strong>Red</h6>
             <h6 className='mt-4'><strong>Taste  :  </strong>Spicy, Strong</h6>
             <h6 className='mt-4'><strong>Use  :  </strong>Seasoning</h6>
            
             <p className="mt-3 aboutContent">
             Red chili, a spice derived from the fruits of Capsicum annuum and related species, is produced in several countries globally, with major producers including India, China, and Mexico. Red chilies are rich in capsaicin, the compound responsible for their characteristic heat. This spice offers various health benefits, including potential metabolism-boosting properties, pain relief, and antioxidant effects. Culinary uses of red chili are extensive and diverse. It is a fundamental ingredient in many cuisines, adding spice and depth of flavor to dishes. Red chili can be used in powdered form, as flakes, or as whole dried peppers. It is an integral component of spice blends, sauces, and marinades, contributing not only heat but also a unique and vibrant color to culinary creations. Red chili plants thrive in warm climates with well-drained soil and ample sunlight, making them suitable for cultivation in regions with tropical or subtropical conditions. The versatile and pungent red chili is a culinary cornerstone, bringing both heat and flavor to a wide range of dishes across the world.
            </p>
             
       </div>
     </div>
     
     </div>
     </section>
        
   </>
  )
}

export default LalMirch