import React from 'react'

const Ajwain = () => {
  return (
    <>
    <div className='thirdImage'>
        

      
        </div>
      <section className='aboutSection'>
      <div className='container'>
      <div className="row">
        <div className="col-sm-6">
          
            <img src="/Data/Images/productimages/ajwain.png" className="w-75 h-75 border-bottom mt-4 mx-auto aboutImg" alt="jeerapic"/>
           
        </div>
        <div className="col-sm-6 mt-3">
         
              <h1 className="aboutHeading">Carom Seeds (Ajwain)</h1>
              <h6 className='mt-4'><strong>Colour  :  </strong>Green, White, Brown</h6>
              <h6 className='mt-4'><strong>Taste  :  </strong>Spicy, Strong</h6>
              <h6 className='mt-4'><strong>Use  :  </strong>Medicinal, Herbs</h6>
             
              <p className="mt-3 aboutContent">
              Carom seeds, scientifically known as Trachyspermum ammi, are aromatic seeds derived from a herbaceous plant in the parsley family. While not as large in global production as some other spices, India is a significant producer of carom seeds, with states such as Gujarat and Rajasthan playing key roles in cultivation. India's favorable climate and agricultural conditions contribute to the country's prominence in carom seed production. These tiny seeds, also known as ajwain, offer a distinctive, slightly bitter, and pungent flavor profile, making them a popular addition to various culinary applications. Ajwain is widely used in Indian cuisine, lending its unique taste to bread, snacks, and spice blends. Apart from India, carom seeds are also cultivated in countries like Iran, Afghanistan, and Egypt. In addition to their culinary uses, carom seeds are valued in traditional medicine for their digestive properties, further highlighting their significance in both the culinary and wellness realms.
             </p>
              
        </div>
      </div>
      
      </div>
      </section>
         
    </>
  )
}

export default Ajwain