import React from 'react'

import { Link } from "react-router-dom";

import $ from 'jquery';

export default function Navbar({scroll}) {
 
  
  
const hideNavbar = () =>{
  $('.navbar-collapse').collapse('hide');
}


 
    return (
<>
      <nav className={scroll ? "navbar navbar-expand-lg navbar-dark fixed-top bg-scroll" : "navbar navbar-expand-lg navbar-dark fixed-top bg-nonscroll"} >
  <div className="container-fluid">
    
      <Link to="/"><img src="/Data/Images/logo.png" alt="" className='logo' /></Link>
      <button className="navbar-toggler" type="button"  data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon" ></span>
    </button>
    <div className="collapse navbar-collapse"  id="navbarSupportedContent">
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0" >
        <li className="nav-item">
          <Link className="nav-link" aria-current="page" onClick = {hideNavbar} to="/">Home</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/about" onClick = {hideNavbar}>About</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/services" onClick = {hideNavbar}>Services</Link>
        </li>
        <li className="nav-item btn-group">
        <Link className="nav-link" to="/products" role="button" onClick = {hideNavbar}>Products</Link>
          <Link className="nav-link dropdown-toggle dropdown-toggle-split iconsHover" to="/products" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        
          </Link>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><Link className="dropdown-item" to="/product/jeera" onClick = {hideNavbar}>Cumin seeds</Link></li>
            <li><Link className="dropdown-item" to="/product/ajwain" onClick = {hideNavbar}>Carom seeds</Link></li>
            <li><Link className="dropdown-item" to="/product/saunf" onClick = {hideNavbar}>Fennel seeds</Link></li>
           
            <li><Link className="dropdown-item" to="/product/dhania" onClick = {hideNavbar}>Coriander seeds</Link></li>
            <li><Link className="dropdown-item" to="/product/rai" onClick = {hideNavbar}>Mustard seeds</Link></li>
            <li><Link className="dropdown-item" to="/product/methi-dana" onClick = {hideNavbar}>Fenugreek</Link></li>
            <li><Link className="dropdown-item" to="/product/til" onClick = {hideNavbar}>Sesame seeds</Link></li>
            <li><Link className="dropdown-item" to="/product/lal-mirch" onClick = {hideNavbar}>Red-Chilly</Link></li>
          </ul>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/harvestchart" onClick = {hideNavbar}>Harvest Chart</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/contact" onClick = {hideNavbar}>Contact</Link>
        </li>
        
      </ul>
     
      
      
    </div>
  </div>
</nav> 

</>
      
    );
}
