import React from 'react'

const Saunf = () => {
  return (
    <>
     <div className='thirdImage'>
        

      
        </div>
      <section className='aboutSection'>
      <div className='container'>
      <div className="row">
        <div className="col-sm-6">
          
            <img src="/Data/Images/productimages/sauf.png" className="w-75 h-75 border-bottom mt-4 mx-auto aboutImg" alt="jeerapic"/>
           
        </div>
        <div className="col-sm-6 mt-3">
         
              <h1 className="aboutHeading">Fennel Seeds (Saunf)</h1>
              <h6 className='mt-4'><strong>Colour  :  </strong>Green, White, Brown</h6>
              <h6 className='mt-4'><strong>Taste  :  </strong>Sweet, Aromatic</h6>
              <h6 className='mt-4'><strong>Use  :  </strong>Seasoning, Herbs, Medicinal</h6>
             
              <p className="mt-3 aboutContent">
              Fennel seeds, derived from the aromatic flowering plant Foeniculum vulgare, are cultivated for their culinary and medicinal properties. Primary fennel seed producers include India, China, and Egypt. These small, oblong seeds boast a sweet, licorice-like flavor and are commonly used in both sweet and savory dishes. Fennel seeds offer several health benefits, including aiding digestion, reducing inflammation, and providing a rich source of antioxidants. In traditional medicine, fennel seeds are often used to alleviate digestive issues and respiratory conditions. Culinary applications of fennel seeds are diverse; they are employed in baking, seasoning meats, and flavoring soups and stews. Fennel seeds are also a key ingredient in spice blends, such as Indian masalas and Chinese five-spice powder. Cultivation of fennel seeds typically requires well-drained soil and a sunny environment. The plant thrives in moderate temperatures and is often recommended for growth in regions with climates resembling the Mediterranean. Overall, fennel seeds contribute both flavor and potential health benefits to a wide range of culinary and wellness practices.






             </p>
              
        </div>
      </div>
      
      </div>
      </section>
         
    </>
  )
}

export default Saunf