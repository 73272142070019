import React from 'react'

const MethiDana = () => {
  return (
    <>
   <div className='thirdImage'>
        

      
        </div>
     <section className='aboutSection'>
     <div className='container'>
     <div className="row">
       <div className="col-sm-6">
         
           <img src="/Data/Images/productimages/Methi-dana.png" className="w-75 h-75 border-bottom mt-4 mx-auto aboutImg" alt="jeerapic"/>
          
       </div>
       <div className="col-sm-6 mt-3">
        
             <h1 className="aboutHeading">Fenugreek (Methi-Dana)</h1>
             <h6 className='mt-4'><strong>Colour  :  </strong>Yellow, Brown</h6>
             <h6 className='mt-4'><strong>Taste  :  </strong>Bitter</h6>
             <h6 className='mt-4'><strong>Use  :  </strong>Medicinal, Herbs</h6>
            
             <p className="mt-3 aboutContent">
             Fenugreek, derived from the Trigonella foenum-graecum plant, is cultivated for its seeds, leaves, and as a forage crop. Major fenugreek producers include India, China, and Ethiopia. The small, golden-brown fenugreek seeds have a slightly bitter taste and a distinctive aroma. These seeds offer a range of health benefits, including potential anti-inflammatory and antioxidant properties. Fenugreek is known to aid digestion, regulate blood sugar levels, and may have benefits for lactating mothers in promoting milk production. In culinary applications, fenugreek seeds are commonly used in Indian, Middle Eastern, and North African cuisines. They are a key ingredient in spice blends, such as curry powders, and are often tempered in hot oil before being added to dishes. Fenugreek plants thrive in well-drained soil and are suitable for cultivation in regions with a Mediterranean-like climate. The leaves of the fenugreek plant, known as methi in India, are also used as a leafy green in cooking. Fenugreek's diverse applications, combined with its potential health benefits, make it a valued and multifaceted herb in both culinary and traditional medicine practices worldwide.
            </p>
             
       </div>
     </div>
     
     </div>
     </section>
        
   </>
  )
}

export default MethiDana