import React from 'react'
import { Link } from 'react-router-dom'





export default function Header() {
    return (
        <>
        
        <div className='hero-image '>
   
 
  
  <div className='hero-text'>
    <h1>Shri Shyam Spices Pvt. Ltd.</h1>
    
    <p>Leading Manufacturer and Supplier of best quality spices </p>
    <Link to = "/contact" className = "btn btn-warning shadow">Contact Us</Link>
    </div>
 
</div>
</>
    )
}

